import React from 'react';
import AkcijeAdmin from './AkcijeAdmin';
import PaketiAdmin from './PaketiAdmin';
import AdminAnalytics from './AdminAnalytics';
import AgentLeaderboard from './AgentLeaderboard';

const AdminPage = () => {

    return (
        <div>
            <h1>Admin</h1>
            <AgentLeaderboard />
            <AdminAnalytics />

            
            <AkcijeAdmin />
            <PaketiAdmin />
            
        </div>
    );
};

export default AdminPage;