import React, { useEffect, useState } from 'react';
import { DataTable, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, DatePicker, DatePickerInput, Button } from 'carbon-components-react';
import PocketBase from 'pocketbase';
import { useAuth } from '../../AuthContext';

const AgentLeaderboard = () => {
  const { isAuthenticated, currentUserId, currentUserFullName, currentUserPhoneNum, currentUserEmail, pb, login, logout, isTestDatabase } = useAuth();
  const [agents, setAgents] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchAgentData = async () => {
    try {
      let filterString = '';
  
      if (startDate && endDate) {
        const startDateFormatted = `${startDate}T00:00:00Z`;
  
        // Add one day to the endDate to include the whole day
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const endDateFormatted = `${nextDay.toISOString().split('T')[0]}T00:00:00Z`;
  
        filterString = `created >= "${startDateFormatted}" && created < "${endDateFormatted}"`;
      }
  
      const users = await pb.collection('users').getFullList();
  
      const orders = await pb.collection('narocilo').getFullList({
        filter: filterString,
      });
  
      const orderCountByAgent = {};
      orders.forEach(order => {
        const agentId = order.agent;
        if (agentId in orderCountByAgent) {
          orderCountByAgent[agentId]++;
        } else {
          orderCountByAgent[agentId] = 1;
        }
      });
  
      const agentsWithOrderCount = users.map(user => ({
        ...user,
        orderCount: orderCountByAgent[user.id] || 0
      }));
  
      agentsWithOrderCount.sort((a, b) => b.orderCount - a.orderCount);
  
      setAgents(agentsWithOrderCount);
    } catch (error) {
      console.error('Error fetching agent data:', error);
    }
  };

  useEffect(() => {
    fetchAgentData();
  }, []);

  const handleFetch = () => {
    fetchAgentData();
  };

  const handleStartDateChange = (event) => {
    const selectedDate = event[0];
    if (selectedDate) {
      setStartDate(selectedDate.toISOString().split('T')[0]);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event[0]; 
    if (selectedDate) {
      setEndDate(selectedDate.toISOString().split('T')[0]);
    } else {
      setEndDate('');
    }
  };

  const headers = [
    { key: 'avatar', header: 'Avatar' },
    { key: 'name', header: 'Ime' },
    { key: 'orderCount', header: 'Naročila' },
  ];

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
        <DatePicker datePickerType="single" onChange={handleStartDateChange}>
          <DatePickerInput
            id="start-date-picker"
            labelText="Od"
            placeholder="YYYY-MM-DD"
          />
        </DatePicker>
        <DatePicker datePickerType="single" onChange={handleEndDateChange}>
          <DatePickerInput
            id="end-date-picker"
            labelText="Do"
            placeholder="YYYY-MM-DD"
          />
        </DatePicker>
        <Button onClick={handleFetch}>Pridobi</Button>
      </div>

      <DataTable
        rows={agents.map(agent => {
          const avatarUrl = agent.avatar
            ? `https://dev.agentron.net:8444/api/files/_pb_users_auth_/${agent.id}/${agent.avatar}`
            : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png';
          return {
            id: agent.id,
            avatar: avatarUrl,
            name: agent.name || agent.username,
            orderCount: agent.orderCount
          };
        })}
        headers={headers}
        render={({ rows, headers, getHeaderProps, getRowProps }) => (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  <TableCell>
                    <img
                      src={row.cells.find(cell => cell.info.header === 'avatar').value}
                      alt={row.cells.find(cell => cell.info.header === 'name').value}
                      style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                  </TableCell>
                  <TableCell>{row.cells.find(cell => cell.info.header === 'name').value}</TableCell>
                  <TableCell>{row.cells.find(cell => cell.info.header === 'orderCount').value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
    </div>
  );
};

export default AgentLeaderboard;