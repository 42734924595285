import React, { useState, useEffect } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination,
  TextInput,
  Button,
  Modal,
} from 'carbon-components-react';
import { useAuth } from '../../AuthContext';
import PocketBase from 'pocketbase';


const NarocilaTable = () => {
  const pb  = useAuth();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // For viewing details
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  // Fetch narocilo records with the expanded 'agent' relation
  const fetchNarocila = async () => {
    setLoading(true);
    try {
      const resultList = await pb.collection('narocilo').getList(page, perPage, {
        filter: `ime ~ "${searchQuery}" || priimek ~ "${searchQuery}" || email ~ "${searchQuery}" || operater_donor ~ "${searchQuery}" || narocnik ~ "${searchQuery}"`,
        sort: '-created',
        expand: 'agent', // Expand the 'agent' relation to get the agent's details
      });
      setData(resultList.items);
      setTotalItems(resultList.totalItems);
    } catch (error) {
      console.error('Error fetching narocila:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNarocila();
  }, [page, perPage, searchQuery]);

  // Show only a few essential fields in the table
  const visibleFields = ['ime', 'priimek', 'kontakt_tel', 'email', 'popust'];

  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRecord(null);
  };

  // Fields to display in the modal, mapping to PocketBase fields in the required sequence
  const modalFields = [
    { label: 'Ime in priimek/naziv podjetja', value: (record) => `${record.ime} ${record.priimek}` },
    { label: 'Naslov stalnega bivališča/sedež podjetja', value: (record) => `${record.ulica} ${record.hisna_st || ''}` },
    { label: 'Poštna št. in kraj', value: (record) => `${record.postna_st || 'N/A'}, ${record.posta || 'N/A'}` },
    { label: 'Davčna št.', value: 'davcna_st' },
    { label: 'Zavezanec', value: 'zavezanec_za_ddv' },
    { label: 'Kontaktna telefonska št.', value: 'kontakt_tel' },
    { label: 'Vrsta osebnega dokumenta', value: 'osebni_dokument' },
    { label: 'Zastopnik podjetja', value: 'zastopnik' },
    { label: 'Matična št. podjetja', value: 'maticna_st_podjetje' },
    { label: 'Elektronski naslov', value: 'email' },
    { label: '1. Podatki potrebni za sklenitev naročniškega razmerja', value: '' },
    {
      label: 'Izbrani naročniški paket in dodatne storitve',
      value: (record) => record.popust || '—', // Showing the value of 'Popust na mesečno naročnino' here
    },
    { label: '2. Podatki potrebni za izpolnitev obrazca za prenos številke k drugemu operaterju', value: '—' },
    { label: 'Mobilna telefonska številka, ki jo želim prenesti (SIM 1)', value: '—' },
    { label: 'Operater dajalec številke (donor)', value: '—' },
    { label: 'Številka računa za opravljene mobilne storitve operaterja dajalca', value: '—' },
    { label: 'Ime in priimek/podjetje', value: (record) => `${record.ime} ${record.priimek}` },
    { label: 'Naslov/sedež družbe', value: (record) => `${record.naslov || '—'} ${record.hisna_st || ''}` },
    { label: 'Poštna št. in kraj', value: (record) => `${record.postna_st || '—'}, ${record.posta || '—'}` },
    { label: 'Davčna št.', value: 'davcna_st' },
    { label: '3. PODATKI, POTREBNI ZA SKLENITEV ANEKSA K NAROČNIŠKI POGODBI', value: '—' },
    { label: 'Proizvajalec', value: '—' },
    { label: 'Model', value: '—' },
    { label: 'Število obrokov (obkroži)', value: '—' },
    { label: 'Dolžina minimalnega obdobja (obdobje vezave)', value: '—' },
    { label: '4. Podatki potrebni za sklenitev aneksa k naročniški pogodbi', value: '—' },
    { label: 'Popust na mesečno naročnino', value: '—' }, // Leave empty as it is shown above under "Izbrani naročniški paket"
    { label: 'Ime in priimek/naziv podjetja', value: (record) => `${record.ime} ${record.priimek}` },
    { label: 'Naslov stalnega bivališča/sedež podjetja', value: (record) => `${record.ulica || '—'} ${record.hisna_st || ''}` },
    { label: 'Poštna št. in kraj', value: (record) => `${record.postna_st || '—'}, ${record.posta || '—'}` },
    { label: 'Št. kupca (naročniška številka)', value: '—' },
    { label: '5. Način pošiljanja računov', value: '' },
    { label: 'Račune želim prejemati v elektronski obliki in sicer', value: '' },
    { label: 'SMS račun', value: 'racun_sms' },
    { label: 'Po elektronski pošti na naslov', value: 'racun_email_naslov' },
    { label: 'V spletno banko (IBAN)', value: 'racun_banka_iban' },
    { label: 'V naročniški portal Telemach', value: 'racun_portal' },
    { label: 'Račune želim prejemati v pisni obliki in sicer', value: '' },
    { label: 'Naslov (ulica oz. naselje, hišna številka)', value: (record) => `${record.ulica || '—'} ${record.hisna_st || ''}` },
    { label: 'Poštna št.', value: 'postna_st' },
    { label: 'Mesto', value: 'posta' },
    { label: 'Datum in podpis naročitelja', value: 'datum_podpis_narocitelja' },
    { label: 'Datum in podpis naročnika fiksnih storitev', value: 'datum_podpis_fiksnih_storitev' },
    { label: 'Ime in priimek/podjetje (posrednik)', value: 'ime_posrednik' },
    { label: 'Ime agenta', value: (record) => record.expand?.agent?.name || '—' }, // Display the agent's name
  ];

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Seznam naročil</h1>

      <TextInput
        id="search"
        labelText="Išči naročila"
        placeholder="Išči po kateremkoli polju ali relaciji"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: '1rem' }}
      />

      <TableContainer title="Naročila" description="Seznam vseh naročil">
        <Table isSortable>
          <TableHead>
            <TableRow>
              {visibleFields.map((field) => (
                <TableHeader key={field}>{field}</TableHeader>
              ))}
              <TableHeader>Dejanja</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={visibleFields.length + 1}>Nalaganje...</TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={row.id}>
                  {visibleFields.map((field) => (
                    <TableCell key={field}>{row[field]?.toString() || '—'}</TableCell>
                  ))}
                  <TableCell>
                    <Button
                      kind="tertiary"
                      onClick={() => handleViewDetails(row)}
                      size="small"
                    >
                      Ogled podrobnosti
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalItems={totalItems}
        pageSize={perPage}
        pageSizes={[5, 10, 20, 50]}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPerPage(pageSize);
        }}
      />

      {selectedRecord && (
        <Modal
          open={isModalOpen}
          modalHeading="Podrobnosti naročila"
          passiveModal
          onRequestClose={handleCloseModal}
        >
          <div>
            {modalFields.map((field) => (
              <p key={field.label}>
                <strong>{field.label}: </strong>
                {typeof field.value === 'function'
                  ? field.value(selectedRecord)
                  : selectedRecord[field.value]?.toString() || '—'}
              </p>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NarocilaTable;